import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { AudioLoaderComponent } from '@app/shared/components/audio-loader/audio-loader.component';
import { NoAccessContainerComponent } from '@app/shared/components/no-access-container/no-access-container.component';
import { FoldContainerSidebarComponent } from '@app/shared/components/shell/fold-container-sidebar/fold-container-sidebar.component';
import { HeaderPlayerComponent } from '@app/shared/components/shell/header-player/header-player.component';
import { HeaderUploadLineComponent } from '@app/shared/components/shell/header-upload-line/header-upload-line.component';
import { HeaderUploadComponent } from '@app/shared/components/shell/header-upload/header-upload.component';
import { HeaderComponent } from '@app/shared/components/shell/header/header.component';
import { RadioSidebarHeaderComponent } from '@app/shared/components/shell/radio-sidebar-header/radio-sidebar-header.component';
import { RadioSidebarMainComponent } from '@app/shared/components/shell/radio-sidebar-main/radio-sidebar-main.component';
import { RadioSidebarComponent } from '@app/shared/components/shell/radio-sidebar/radio-sidebar.component';
import { SlideshowComponent } from '@app/shared/components/slideshow/slideshow.component';
import { UpdateAvailableComponent } from '@app/shared/components/update-available/update-available.component';
import { ApollonUpdateModalComponent } from '@app/shared/modals/apollon-update-modal/apollon-update-modal.component';
import { ChangeRadioModalComponent } from '@app/shared/modals/change-radio-modal/change-radio-modal.component';
import { UpgradeDiscoverModalComponent } from '@app/shared/modals/upgrade-discover-modal/upgrade-discover-modal.component';
import { FaqComponent } from '@app/shared/modals/upgrade-offer/components/faq/faq.component';
import { OfferComponent } from '@app/shared/modals/upgrade-offer/components/offer/offer.component';
import { TrustpilotComponent } from '@app/shared/modals/upgrade-offer/components/trustpilot/trustpilot.component';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { FuseNavigationComponent } from '@app/shared/modules/navigation/navigation.component';
import { FuseNavigationService } from '@app/shared/modules/navigation/navigation.service';
import { RkNavVerticalCollapseComponent } from '@app/shared/modules/navigation/vertical/nav-collapse/nav-vertical-collapse.component';
import { RkNavVerticalItemComponent } from '@app/shared/modules/navigation/vertical/nav-item/nav-vertical-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { OnboardingChecklistModule } from '@radioking/onboarding-checklist';
import { SharedPanelManagerModule } from '@radioking/shared/pannel-manager';
import { UiAdvancedTooltipModule } from '@radioking/ui/advanced-tooltip';
import { UiAvatarModule } from '@radioking/ui/avatar';
import { UiButtonModule } from '@radioking/ui/button';
import { UiColoredHintModule } from '@radioking/ui/colored-hint';
import { UiCustomModalWrapperModule } from '@radioking/ui/custom-modal-wrapper';
import { UiFoldItemModule } from '@radioking/ui/fold-item';
import { UiHelpLinkModule } from '@radioking/ui/help-link';
import { UiHintStarModule } from '@radioking/ui/hint-star';
import { UiInputModule } from '@radioking/ui/input';
import { UiLoaderModule } from '@radioking/ui/loader';
import { UiLockedIconModule } from '@radioking/ui/locked-icon';
import { UiMobileRadioActionsModule } from '@radioking/ui/mobile-radio-actions';
import { UiMobileSideHeaderModule } from '@radioking/ui/mobile-side-header';
import { UiMobileUserMenuModule } from '@radioking/ui/mobile-user-menu';
import { UiModalWrapperModule } from '@radioking/ui/modal-wrapper';
import { UiPerfectScrollBarModule } from '@radioking/ui/perfect-scroll-bar';
import { UiPlaylistIconModule } from '@radioking/ui/playlist-icon';
import { UiSortByModule } from '@radioking/ui/sort-by';
import { UiTooltipModule } from '@radioking/ui/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { MomentModule } from 'ngx-moment';
import { SwiperModule } from 'swiper/angular';

import { DirectivesModule } from '../../../../../libs/directives/directives.module';
import { PipesModule } from '../../../../../libs/shared/pipes/pipes.module';
import { UiStepIndicatorModule } from '../../../../../libs/ui/step-indicator/src';

import { MobOnboardingComponent } from './components/mob-onboarding/mob-onboarding.component';

const uiModules = [
  UiFoldItemModule,
  UiButtonModule,
  UiLoaderModule,
  UiSortByModule,
  UiHelpLinkModule,
  UiModalWrapperModule,
  UiHintStarModule,
  UiPerfectScrollBarModule,
  UiAdvancedTooltipModule,
  UiColoredHintModule,
  UiCustomModalWrapperModule,
  UiMobileUserMenuModule,
  UiMobileRadioActionsModule,
  UiInputModule,
  UiStepIndicatorModule,
];

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    ContextMenuModule.forRoot(),
    TranslateModule.forChild(),
    SharedPanelManagerModule,
    ColorPickerModule,
    ScrollingModule,
    MomentModule,
    ...uiModules,
    DirectivesModule,
    PipesModule,
    UiTooltipModule,
    UiMobileSideHeaderModule,
    UiPlaylistIconModule,
    OnboardingChecklistModule,
    SwiperModule,
    CdkAccordionModule,
    MatExpansionModule,
    UiAvatarModule,
    UiLockedIconModule,
  ],
  providers: [FuseNavigationService],
  declarations: [
    AudioLoaderComponent,
    HeaderComponent,
    RadioSidebarComponent,
    RadioSidebarHeaderComponent,
    RadioSidebarMainComponent,
    HeaderPlayerComponent,
    ChangeRadioModalComponent,
    ApollonUpdateModalComponent,
    UpgradeOfferModalComponent,
    OfferComponent,
    TrustpilotComponent,
    FaqComponent,
    FuseNavigationComponent,
    RkNavVerticalItemComponent,
    RkNavVerticalCollapseComponent,
    NoAccessContainerComponent,
    UpdateAvailableComponent,
    HeaderUploadComponent,
    HeaderUploadLineComponent,
    FoldContainerSidebarComponent,
    SlideshowComponent,
    MobOnboardingComponent,
    UpgradeDiscoverModalComponent,
  ],
  exports: [
    AudioLoaderComponent,
    ContextMenuModule,
    HeaderComponent,
    RadioSidebarComponent,
    RadioSidebarHeaderComponent,
    RadioSidebarMainComponent,
    HeaderPlayerComponent,
    FuseNavigationComponent,
    NoAccessContainerComponent,
    UpdateAvailableComponent,
    SharedPanelManagerModule,
    MomentModule,
    FoldContainerSidebarComponent,
    ...uiModules,
    SlideshowComponent,
  ],
})
export class SharedModule {}
