import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthState } from '@app/core/states/auth.state';
import { RadioStartRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import {
  AddDefaultTracksFailure,
  AddDefaultTracksRequest,
  AddDefaultTracksSuccess,
} from '@app/library/states/tracks.actions';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { OnboardingStep } from '@app/shared/components/slideshow/slideshow.component';
import { SavedToLocalstorage } from '@app/shared/constants';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import {
  MobileSidenavService,
  UiStoreHelperService,
} from '@radioking/shared/common-services';
import { combineLatest, fromEvent, Observable, Subscription, zip } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'rk-mob-onboarding',
  templateUrl: './mob-onboarding.component.html',
  styleUrls: ['./mob-onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobOnboardingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(AuthState.currentFirstname)
  user$: Observable<string>;

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  @Select(RadioState.demoPeriodRadio)
  newRadio$: Observable<boolean>;

  @Select(RadioState.currentRadioSlug)
  slug$: Observable<string>;

  isImportingDefaultTracks$: Observable<boolean>;

  defaultTracksAdded = false;
  fileExt = '';
  stepId = 0;
  step: OnboardingStep;
  steps: OnboardingStep[];

  subscription = new Subscription();

  constructor(
    private readonly translate: TranslateService,
    private readonly mobileSidenavService: MobileSidenavService,
    private readonly actions$: Actions,
    private readonly uiHelper: UiStoreHelperService,
    private readonly host: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    combineLatest([this.user$, this.userLang$, this.newRadio$]).subscribe(
      ([user, lang, newRadio]) => this.setSteps(user, lang, newRadio),
    );
    this.isImportingDefaultTracks$ = this.uiHelper.getLoadingState(
      AddDefaultTracksRequest,
      [AddDefaultTracksSuccess, AddDefaultTracksFailure],
    );
    this.subscription.add(
      this.actions$.pipe(ofActionDispatched(AddDefaultTracksSuccess)).subscribe(() => {
        this.updateStepsAfterDefaultTracks();
        this.startRadio();
      }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      zip(
        fromEvent<TouchEvent>(this.host.nativeElement, 'touchstart'),
        fromEvent<TouchEvent>(this.host.nativeElement, 'touchend').pipe(
          withLatestFrom(fromEvent<TouchEvent>(this.host.nativeElement, 'touchmove')),
        ),
      ).subscribe({
        next: ([touchstartEvent, [, touchendEvent]]) => {
          const xDiff =
            touchstartEvent.touches[0].clientX - touchendEvent.touches[0].clientX;
          if (Math.abs(xDiff) > 0.25 * document.body.clientWidth) {
            if (this.stepId > 0 && xDiff < 0) {
              // Swipe left to right
              this.prevStep();
            } else if (this.stepId < this.steps.length - 1 && xDiff > 0) {
              // Swipe right to left
              this.nextStep();
            }
            this.cdr.detectChanges();
          }
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setSteps(user: string, lang: string, newRadio: boolean) {
    if (lang === 'fr') {
      this.fileExt = '-fr';
    }
    if (newRadio) {
      this.steps = [
        {
          title: this.translate.instant('SLIDESHOW_MOB.WELCOME') + ' ' + user,
          description: this.translate.instant('SLIDESHOW_MOB.WELCOME_DESC'),
          src: 'hello.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_START,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.MOBILE_AVAILABLE'),
          description: this.translate.instant('SLIDESHOW_MOB.MOBILE_AVAILABLE_DESC'),
          src: 'on-mobile' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_1,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.FREE_TITLES'),
          description: this.translate.instant('SLIDESHOW_MOB.FREE_TITLES_DESC'),
          src: 'free-list' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_2,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.RADIO_AVAILABLE_SOON'),
          description: this.translate.instant('SLIDESHOW_MOB.RADIO_AVAILABLE_SOON_DESC'),
          src: 'start-radio' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_2_NO_1,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.SHARE_RADIO'),
          description: this.translate.instant('SLIDESHOW_MOB.SHARE_RADIO_LATER_DESC'),
          src: 'share' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_2_NO_2,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.CONGRATS'),
          description: this.translate.instant('SLIDESHOW_MOB.CONGRATS_DESC'),
          src: 'congrats.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_END,
        },
      ];
    } else {
      this.steps = [
        {
          title: this.translate.instant('SLIDESHOW_MOB.MOBILE_AVAILABLE'),
          description: this.translate.instant('SLIDESHOW_MOB.MOBILE_AVAILABLE_DESC'),
          src: 'on-mobile' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.START,
        },
        {
          title: this.translate.instant('SLIDESHOW_MOB.LETS_GO'),
          description: this.translate.instant('SLIDESHOW_MOB.LETS_GO_DESC'),
          src: 'share' + this.fileExt + '.mp4',
          amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.END,
        },
      ];
    }
    this.step = this.steps[0];
    aLogEvent(this.step.amplitudeEvent);
  }

  nextStep() {
    this.stepId++;
    this.step = this.steps[this.stepId];
    aLogEvent(this.step.amplitudeEvent);
  }

  prevStep() {
    this.stepId--;
    this.step = this.steps[this.stepId];
  }

  close() {
    localStorage.setItem(SavedToLocalstorage.ONBOARDING_MOBILE, '1');
    this.mobileSidenavService.close();
    if (this.steps.length > 1) {
      const btn = document.querySelector('rk-header button.menu-button');
      if (btn instanceof HTMLElement) {
        btn.click();
      }
    }
  }

  updateStepsAfterDefaultTracks() {
    this.defaultTracksAdded = true;
    const newSteps: OnboardingStep[] = [
      {
        title: this.translate.instant('SLIDESHOW_MOB.RADIO_AVAILABLE_NOW'),
        description: this.translate.instant('SLIDESHOW_MOB.RADIO_AVAILABLE_NOW_DESC'),
        src: 'start-radio' + this.fileExt + '.mp4',
        amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_2_YES_1,
      },
      {
        title: this.translate.instant('SLIDESHOW_MOB.SHARE_RADIO'),
        description: this.translate.instant('SLIDESHOW_MOB.SHARE_RADIO_NOW_DESC'),
        src: 'share' + this.fileExt + '.mp4',
        amplitudeEvent: AMPLITUDE_EVENT.ONBOARDING_MOBILE.NEW_STEP_2_YES_2,
      },
    ];
    this.steps.splice(3, 2, ...newSteps);
  }

  radioSmartlink(slug: string) {
    return environment.urls.SMARTLINK + slug;
  }

  @Dispatch()
  addDefaultTracks = () => {
    aLogEvent(AMPLITUDE_EVENT.ONBOARDING_MOBILE.ADD_TRACKS);

    return new AddDefaultTracksRequest();
  };

  @Dispatch()
  startRadio = () => new RadioStartRequest();
}
