import { Injectable } from '@angular/core';
import { RADIO_PLAN_DESC } from '@app/core/models/Radio';
import {
  LogButton,
  LogButtonToggle,
  LogEvent,
  LogModal,
  LogNav,
  LogRadioButton,
  LogSwitch,
  SetEventProperties,
  SetUserProperties,
} from '@app/core/states/events-tracking.actions';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';

export interface UserProperties {
  user_ID?: number;
  plan_ID?: number;
  plan_name?: RADIO_PLAN_DESC;
}

export interface EventProperties {
  name?: string;
  component?: string;
  origin?: string;
  origin_url?: string;
  status?: string;
}

export interface EventsTrackingStateModel {
  eventProperties: EventProperties;
  userProperties: UserProperties;
}

@State<EventsTrackingStateModel>({
  name: 'EventsTracking',
  defaults: {
    eventProperties: null,
    userProperties: null,
  },
})
@Injectable()
export class EventsTrackingState {
  @Action(LogEvent)
  logEvent(ctx: StateContext<EventsTrackingStateModel>, { event }: LogEvent) {
    aLogEvent(event, ctx.getState().eventProperties);
    ctx.patchState({ eventProperties: null });
  }

  @Action(SetEventProperties)
  setEventProperties(
    ctx: StateContext<EventsTrackingStateModel>,
    { eventProperties }: SetEventProperties,
  ) {
    ctx.setState(
      patch({
        eventProperties: patch({
          ...eventProperties,
          origin_url: window.location.href,
        }),
      }),
    );
  }

  @Action(SetUserProperties)
  setUserProperties(
    ctx: StateContext<EventsTrackingStateModel>,
    { userProperties }: SetUserProperties,
  ) {
    ctx.setState(
      patch({
        userProperties: patch({
          ...userProperties,
        }),
      }),
    );
  }

  @Action(LogNav)
  logNav(ctx: StateContext<EventsTrackingStateModel>, { event, subnav }: LogNav) {
    ctx.dispatch(
      new SetEventProperties({ component: subnav ? 'Sub menu' : 'Navigation' }),
    );
    ctx.dispatch(new LogEvent(event));
  }

  @Action(LogModal)
  logModal(ctx: StateContext<EventsTrackingStateModel>, { properties }: LogModal) {
    ctx.dispatch(new SetEventProperties({ ...properties, component: 'Modal' }));
    ctx.dispatch(new LogEvent(AMPLITUDE_EVENT.MODAL.NAME));
  }

  @Action(LogButton)
  logButton(ctx: StateContext<EventsTrackingStateModel>, { properties }: LogModal) {
    ctx.dispatch(new SetEventProperties({ ...properties, component: 'Button' }));
    ctx.dispatch(new LogEvent(AMPLITUDE_EVENT.BUTTON.NAME));
  }

  @Action(LogButtonToggle)
  logButtonToggle(
    ctx: StateContext<EventsTrackingStateModel>,
    { properties }: LogButtonToggle,
  ) {
    ctx.dispatch(new SetEventProperties({ ...properties, component: 'Button toggle' }));
    ctx.dispatch(new LogEvent(AMPLITUDE_EVENT.BUTTON_TOGGLE.NAME));
  }

  @Action(LogSwitch)
  logSwitch(ctx: StateContext<EventsTrackingStateModel>, { properties }: LogSwitch) {
    ctx.dispatch(new SetEventProperties({ ...properties, component: 'Switch' }));
    ctx.dispatch(new LogEvent(AMPLITUDE_EVENT.SWITCH.NAME));
  }

  @Action(LogRadioButton)
  logRadioButton(
    ctx: StateContext<EventsTrackingStateModel>,
    { properties }: LogRadioButton,
  ) {
    ctx.dispatch(new SetEventProperties({ ...properties, component: 'Radio button' }));
    ctx.dispatch(new LogEvent(AMPLITUDE_EVENT.RADIO_BUTTON.NAME));
  }
}
