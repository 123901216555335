import { Track } from '@app/core/models/Track';

const tag = '[EditingTrack]';

export class StartEditTrack {
  static readonly type = `${tag} start edit track`;
  constructor(public track: Track, public mobile = false) {}
}

export class ForceStartEditTrack {
  static readonly type = `${tag} force start edit track`;
  constructor(public track: Track) {}
}

export class StartEditMultipleTracks {
  static readonly type = `${tag} start edit multiple tracks`;
  constructor(public tracks: Track[]) {}
}

export class ForceStartEditMultipleTracks {
  static readonly type = `${tag} force start edit multiple tracks`;
  constructor(public tracks: Track[]) {}
}

export class ResetEditingTracks {
  static readonly type = `${tag} reset edit tracks`;
}

export class SetActionAfterEditTrack {
  static readonly type = `${tag} set action after edit track`;
  constructor(public action: any) {}
}
