import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { appToasts } from '@app/app.toasts';
import { PlanRestrictionRedirectService } from '@app/core/services/plan-restriction-redirect.service';
import { I18nService } from '@app/core/services/i18n.service';
import { RequestUser } from '@app/core/states/auth.actions';
import { UploadState } from '@app/core/states/upload.state';
import { loadFacebookPixel } from '@app/facebook-pixel';
import { SavedToSessionstorage } from '@app/shared/constants';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  GtmService,
  PageClickService,
  ToasterService,
} from '@radioking/shared/common-services';
import { Logger } from '@radioking/shared/logger';
import { combineLatest, merge, of } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'rk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly i18nService: I18nService,
    private readonly toasterService: ToasterService,
    private readonly gtmService: GtmService,
    private readonly pageClickService: PageClickService,
    private readonly discoverService: PlanRestrictionRedirectService,
  ) {
    const routerEvents = router.events.subscribe(event => {
      if (event instanceof NavigationStart && !router.navigated) {
        sessionStorage.removeItem(SavedToSessionstorage.MEMORIZED_SEARCH_PROGRAMS);
        sessionStorage.removeItem(SavedToSessionstorage.MEMORIZED_STATS_START_DATE);
        sessionStorage.removeItem(SavedToSessionstorage.MEMORIZED_STATS_END_DATE);
        sessionStorage.removeItem(SavedToSessionstorage.MEMORIZED_STATS_INTERVAL);
        routerEvents.unsubscribe();
      }
    });
  }

  @HostListener('window:beforeunload')
  public beforeunloadHandler(): boolean | void {
    if (this.store.selectSnapshot(UploadState.remainingFiles)) {
      return false;
    }
  }

  ngOnInit() {
    loadFacebookPixel();
    this.discoverService.init();

    // Setup logger
    if (environment.disableLogs) {
      Logger.enableProductionMode();
    }
    this.toasterService.setupToasters();
    this.toasterService.registerToasts(appToasts);
    this.store.dispatch(new RequestUser());

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );

    this.gtmService.registerPageChangeEvent();

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd$)
      .pipe(
        mergeMap(() => {
          let route = this.activatedRoute;
          let obs = [route.data];
          while (route.firstChild) {
            route = route.firstChild;
            if (route.outlet === 'primary') {
              obs = [...obs, route.data];
            }
          }
          obs = [...obs, route.data];

          return combineLatest(obs);
        }),
        map(arrs => {
          let ret = {};
          arrs.forEach(item => {
            ret = { ...ret, ...item };
          });

          return ret;
        }),
        map((val: any) => val.title || ''),
        switchMap((title: string) => {
          const globalKey = marker('GLOBAL.WINDOW_TITLE.GLOBAL_SUFFIX');
          const globalTitleStream$ = this.translateService.stream(globalKey);
          if (!title) {
            return combineLatest([of(''), globalTitleStream$]);
          }

          return combineLatest([
            this.translateService.stream(title).pipe(startWith('')),
            globalTitleStream$,
          ]);
        }),
      )
      .subscribe(([title, suffix]) => {
        if (!title) {
          this.titleService.setTitle(suffix);
        } else {
          this.titleService.setTitle(`${title} | ${suffix}`);
        }
      });
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.pageClickService.documentClickedTarget.next(event.target);
  }
}
