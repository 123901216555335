<div class="flex flex-col">
  <ng-container *ngIf="step">
    <div class="flex justify-center w-full px-12 pt-9 bg-brand-light relative">
      <video
        class="w-full aspect-square"
        src="/assets/slideshow/videos/{{ step.src }}"
        autoplay
        loop
        playsinline
      ></video>
      <div
        class="absolute right-5 top-4 text-gray-darkest text-base leading-5"
        (click)="close()"
        translate
      >
        SLIDESHOW.IGNORE
      </div>
      <rk-step-indicator
        class="bottom-3.5"
        [steps]="steps.length"
        [activeStep]="stepId"
      ></rk-step-indicator>
    </div>
    <div class="flex flex-1 flex-col min-h-0">
      <div class="p-5 overflow-auto">
        <div class="title text-2.5xl font-bold" [innerHTML]="step.title"></div>
        <div
          class="text-gray-darkest text-base leading-5 mt-4 mb-2.5"
          [innerHTML]="step.description"
        ></div>
        <rk-button
          class="w-full mt-2.5"
          *ngIf="(newRadio$ | async) && stepId === 2"
          [label]="
            defaultTracksAdded
              ? ('SLIDESHOW.ALREADY_ADDED' | translate)
              : ('SLIDESHOW.YES_ENJOY' | translate)
          "
          [faIcon]="defaultTracksAdded ? 'fa-check' : null"
          [btnStyle]="'orange'"
          [fullWidth]="true"
          [loading]="isImportingDefaultTracks$ | async"
          [disabled]="defaultTracksAdded"
          (click)="addDefaultTracks()"
        ></rk-button>
        <rk-input-with-copy
          *ngIf="
            (defaultTracksAdded && stepId === 4) || (!(newRadio$ | async) && stepId === 2)
          "
          [value]="radioSmartlink(slug$ | async)"
          [disableOpen]="true"
          [keepWidth]="true"
        ></rk-input-with-copy>
      </div>
      <div class="actions mb-6 mx-6 pt-2.5 mt-auto flex">
        <rk-button
          class="flex-1 mr-4"
          *ngIf="stepId > 0"
          (click)="prevStep()"
          [label]="'SLIDESHOW.PREV' | translate"
          [btnStyle]="'orange'"
          [fullWidth]="true"
        ></rk-button>
        <rk-button
          class="flex-1"
          *ngIf="stepId < steps.length - 1"
          (click)="nextStep()"
          [label]="
            stepId === 0
              ? ('SLIDESHOW_MOB.BEGIN' | translate)
              : ('SLIDESHOW.NEXT' | translate)
          "
          [fullWidth]="true"
          [disabled]="isImportingDefaultTracks$ | async"
        ></rk-button>
        <rk-button
          class="flex-1"
          *ngIf="stepId === steps.length - 1"
          (click)="close()"
          [label]="'SLIDESHOW.DISCOVER' | translate"
          [fullWidth]="true"
        ></rk-button>
      </div>
    </div>
  </ng-container>
</div>
