<mat-toolbar class="mat-elevation-z6 navbar">
  <button
    [class.hidden]="!isMobile"
    class="menu-button"
    mat-icon-button
    (click)="toggleSideNav()"
  >
    <mat-icon fontSet="fa" fontIcon="fa-bars"></mat-icon>
  </button>
  <div><rk-header-player></rk-header-player></div>
  <span class="flex flex-1 justify-center">
    <span class="alert-admin-radio" *ngIf="!(isOneOfMineRadios$ | async)"
      >{{ 'HEADER.ALERT_ADMIN_RADIO' | translate }}
      <span class="link" (click)="goBackToMyRadio()">{{
        'HEADER.ALERT_ADMIN_BACK' | translate
      }}</span>
    </span>
  </span>
  <ng-container *ngIf="canShowI18nChange">
    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
      <mat-icon fontSet="fa" fontIcon="fa-globe"></mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      <button
        mat-menu-item
        *ngFor="let language of supportedLangs$ | async"
        (click)="setLanguage(language)"
      >
        {{ language }}
      </button>
    </mat-menu>
  </ng-container>

  <div class="right-side flex">
    <rk-header-upload></rk-header-upload>
    <div class="headway-widget hidden md:flex">
      <mat-progress-spinner
        [mode]="'determinate'"
        [value]="100"
        [diameter]="35"
        [strokeWidth]="1"
      >
      </mat-progress-spinner>
      <mat-icon fontSet="fas" fontIcon="fa-bell"></mat-icon>
    </div>
    <ng-container *ngIf="(radioPlan$ | async) > 0 && (isCustomer$ | async)">
      <button
        class="upgrade flex"
        data-cy="upgrade-offer"
        *rkHasPlan="{ plan: 'Business', condition: 'less' }"
        (click)="updateOffer()"
      >
        <mat-icon class="mr-0 sm:mr-2.5" svgIcon="crown"></mat-icon>
        <span class="hidden sm:inline">
          {{ upgradeLabel() | translate }}
        </span>
      </button>
    </ng-container>
    <button
      *ngIf="!isMobile; else mobileUser"
      class="user-info"
      [matMenuTriggerFor]="userMenu"
      data-cy="user-info-button"
    >
      <span data-cy="user-info-button-name">{{ username$ | async }}</span>
      <mat-icon fontSet="fa" fontIcon="fa-chevron-down"></mat-icon>
    </button>
  </div>

  <ng-template #mobileUser>
    <button
      class="user-info flex !items-center px-4 !py-0"
      data-cy="user-info-button"
      (click)="openPanel()"
    >
      <rk-avatar
        [image]="profilePic$ | async"
        [name]="username$ | async"
        [size]="30"
        data-cy="user-icon"
      ></rk-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-chevron-down"></mat-icon>
    </button>
  </ng-template>

  <mat-menu #userMenu="matMenu" class="user-mat-menu" [overlapTrigger]="false">
    <mat-list>
      <mat-list-item>
        <div class="item-menu-info-user">
          <rk-avatar
            [image]="profilePic$ | async"
            [name]="username$ | async"
            [size]="40"
            data-cy="user-icon"
          ></rk-avatar>
          <div>
            <span data-cy="user-username">{{ username$ | async }}</span>
            <span data-cy="user-email">{{ email$ | async }}</span>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <a target="_blank" [href]="myProfile()">
      <button mat-menu-item>
        <span translate>HEADER.USER.PROFILE</span>
      </button>
    </a>
    <a target="_blank" [href]="clientAreaUrl">
      <button mat-menu-item>
        <span translate>HEADER.USER.CUSTOMER_AREA</span>
      </button>
    </a>
    <a target="_blank" (click)="openKnowledgeBase()" href="https://help.radioking.com/"
      ><button mat-menu-item><span translate>HEADER.USER.HELP</span></button></a
    >
    <a target="_blank" [href]="'HEADER.USER.CHANGELOG_URL' | translate">
      <button mat-menu-item><span translate>HEADER.USER.CHANGELOG</span></button>
    </a>
    <button mat-menu-item (click)="logout()">
      <span translate>HEADER.USER.LOGOUT</span>
    </button>
  </mat-menu>
</mat-toolbar>
<rk-header-player [mobilePlayer]="true"></rk-header-player>
