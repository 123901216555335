import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Preset } from '@app/core/models/Preset';
import { RadioService } from '@app/core/services/radio.service';
import { AuthState, AuthStateModel } from '@app/core/states/auth.state';
import { RadioStatusSuccess } from '@app/core/states/live-tracking.actions';
import { GetRadioSuccess } from '@app/core/states/radio.actions';
import { RadioState, RadioStateModel } from '@app/core/states/radio.state';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';

import {
  GetRolesForRadioFailure,
  GetRolesForRadioRequest,
  GetRolesForRadioSuccess,
} from './authorization.actions';

export class AuthorizationStateModel {
  presets?: Preset[];
  propertiesId?: number;
}

@State<AuthorizationStateModel>({
  name: 'authorization',
  defaults: {},
})
@Injectable()
export class AuthorizationState {
  constructor(
    private readonly radioService: RadioService,
    private readonly store: Store,
  ) {}
  @Selector()
  static roles(state: AuthorizationStateModel): Preset[] {
    return state.presets || [];
  }

  @Selector([RadioState, AuthState])
  static isRoleApplicable(
    state: AuthorizationStateModel,
    radioState: RadioStateModel,
    authState: AuthStateModel,
  ): boolean {
    if (AuthState.isSuperUser(authState)) {
      return false;
    }
    const currRadioId = RadioState.currentRadioId(radioState);

    return (
      RadioState.radios(radioState).find(rdx => rdx.id === currRadioId).role !==
      'customer'
    );
  }

  @Action(GetRolesForRadioRequest)
  getRoles(
    ctx: StateContext<AuthorizationStateModel>,
    { radioId }: GetRolesForRadioRequest,
  ) {
    return this.radioService.getPresetForRadio(radioId).pipe(
      mergeMap(data => ctx.dispatch(new GetRolesForRadioSuccess(data))),
      catchError(err => ctx.dispatch(new GetRolesForRadioFailure(err))),
    );
  }

  @Action(GetRolesForRadioSuccess)
  getRolesSuccess(
    ctx: StateContext<AuthorizationStateModel>,
    { roles }: GetRolesForRadioSuccess,
  ) {
    if (roles) {
      ctx.patchState({
        presets: roles,
      });
    } else {
      ctx.patchState({
        presets: [
          {
            id: -1,
            keyString: '__noroles__',
            translationKey: 'NOROLES',
          },
        ],
      });
    }
  }

  @Action(GetRadioSuccess)
  specificRadioSuccess(
    ctx: StateContext<AuthorizationStateModel>,
    { id }: GetRadioSuccess,
  ): Observable<void> | void {
    const isSuperUser = this.store.selectSnapshot(AuthState.isSuperUser);
    if (isSuperUser) {
      return;
    }
    const radioInStore = this.store
      .selectSnapshot(RadioState.radios)
      .find(rdx => rdx.id === id);
    if (radioInStore && radioInStore.role === 'user') {
      return ctx.dispatch(new GetRolesForRadioRequest(id));
    }
  }

  @Action(RadioStatusSuccess)
  radioStatusSuccess(
    ctx: StateContext<AuthorizationStateModel>,
    { id, status }: RadioStatusSuccess,
  ) {
    if (ctx.getState().propertiesId !== id) {
      const radioProperties = {
        radioId: id,
        radioPlan: status.radioPlan.planId,
        radioPlanName: status.radioPlan.description,
        demo: status.radioPlan.description === 'Demo',
      };
      amplitude.getInstance().setUserProperties(radioProperties);
      ctx.setState(
        patch({
          propertiesId: id,
        }),
      );
    }
    const isSuperUser = this.store.selectSnapshot(AuthState.isSuperUser);
    if (!isSuperUser && status.status === 'inactive') {
      ctx.dispatch(new Navigate([`/radio/${id}/disabled`], {}, { replaceUrl: true }));

      return;
    }
    const radio = this.store.selectSnapshot(RadioState.currentRadio);

    const currUrl = this.store.selectSnapshot(RouterState.url) || '';
    if (
      (this.store.selectSnapshot(RouterState.url) === '/disabled' &&
        status.status !== 'inactive') ||
      (this.store.selectSnapshot(RouterState.url) === '/configure-radio' && radio.name)
    ) {
      ctx.dispatch(new Navigate([`/radio/${id}/`]));

      return;
    }

    if (
      (currUrl.endsWith('/disabled') && status.status !== 'inactive') ||
      (currUrl.endsWith('/configure-radio') && radio.name)
    ) {
      ctx.dispatch(new Navigate([`/radio/${id}/`]));

      return;
    }
    if (!currUrl) {
      this.store
        .select(RouterState.url)
        .pipe(
          filter(val => !!val),
          take(1),
        )
        .subscribe(val => {
          ctx.dispatch(new Navigate([this.replaceRadioIdInUrl(val, id)]));
        });

      return;
    }
    const qpIndex = currUrl.indexOf('?');
    if (qpIndex === -1) {
      // No query param, basic behavior
      ctx.dispatch(new Navigate([this.replaceRadioIdInUrl(currUrl, id)]));
    } else {
      const path = currUrl.substring(0, qpIndex);
      const qp: Params = new URLSearchParams(currUrl.substring(qpIndex + 1));
      ctx.dispatch(
        new Navigate(
          [this.replaceRadioIdInUrl(path, id)],
          this.paramsToObject(qp.entries()),
        ),
      );
    }
  }

  paramsToObject(entries: IterableIterator<[string, string]>): object {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }

    return result;
  }

  replaceRadioIdInUrl(url: string, radioId: number): string {
    const returnedVal = url.replace(/radio\/\d+/, `/radio/${radioId}`);

    return returnedVal;
  }
}
