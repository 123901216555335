export const PLACEHOLDER = 'assets/placeholder-square.png';
export const MIXPOINT_MODAL_ID = 'mixpoint-modal';
export const PLANNING_MIN_WIDTH_SIDE = 1500;

export enum SavedToLocalstorage {
  PREFERENCE_VOLUME = 'preferences.volume',
  PREFERENCE_TABLE_SORT_LIBRARY_AND_TAG = 'preferences.table_sort_library_and_tag',
  PREFERENCE_TABLE_SORT_PLAYLIST = 'preferences.table_sort_playlist',
  ONBOARDING_MOBILE = 'onboarding_mobile',
  PLANNING_6_MONTH_HINT = 'planning_6_month_hint',
  PLANNING_SIDE_PANEL = 'planning_side_panel',
}

export enum SavedToSessionstorage {
  MEMORIZED_SEARCH_PROGRAMS = 'memorized.search_programs',
  MEMORIZED_STATS_START_DATE = 'memorized.stats.start_date',
  MEMORIZED_STATS_END_DATE = 'memorized.stats.end_date',
  MEMORIZED_STATS_INTERVAL = 'memorized.stats.interval',
}
