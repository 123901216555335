<div
  class="root"
  [class.show]="show"
  (click)="close($event)"
  [class]="options.from"
  [class.full-height]="options.bottomFullHeight"
  [class.no-scroll]="options.noScroll"
>
  <div class="bg" [class.no-bg]="!options.hasBackdrop"></div>
  <div class="content">
    <div class="close" (click)="close($event)" *ngIf="options.from === 'bottom'">
      <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
    </div>
    <div
      class="body"
      (click)="touchBody($event)"
      [class.no-padding]="!options.hasPadding"
      [style]="options.style"
      [class.gray]="options.bgType === 'gray'"
    >
      <ng-template #container></ng-template>
    </div>
  </div>
</div>
