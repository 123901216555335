import { Injectable } from '@angular/core';
import { PlayRadio } from '@app/core/states/audio.actions';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { AddToPlaylistSuccess } from '@app/library/states/playlists.actions';
import {
  AddDefaultTracksRequest,
  AddDefaultTracksSuccess,
  TracksUploaded,
} from '@app/library/states/tracks.actions';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';
import {
  GenerateDayRequest,
  RegenerateCurrentDayRequest,
} from '../../../../daily-generation/src/lib/states/daily-generation.actions';
import { PlanningCreateSlotSuccess } from '../../../../planning/src/lib/states/planning.actions';
import { SchedulerAllTracksRequest } from '../../../../scheduler-core/src/lib/states/scheduler-core.actions';

import { ChecklistStep, OnboardingChecklistModel } from '../onboarding-checklist.model';
import { OnboardingChecklistService } from '../services/onboarding-checklist.service';

import {
  CheckListAddPlaylist,
  CheckListAddTrack,
  ChecklistFailure,
  CheckListGenerate,
  CheckListHelpCenter,
  CheckListListenToRadio,
  CheckListPlanning,
  ChecklistRequest,
  CheckListShare,
  ChecklistSuccess,
  CompleteStep,
  DestroyChecklist,
  PutChecklistFailure,
  PutChecklistSuccess,
  ToggleChecklist,
  ToggleChecklistStep,
  ToggleFirstButton,
} from './onboarding-checklist.actions';

export class OnboardingChecklistStateModel {
  steps: OnboardingChecklistModel[];
  opened: boolean;
  completed: boolean;
  stepOpened: ChecklistStep;
  redirectUploadStep: boolean;
  destroyed: boolean;
}

const steps: OnboardingChecklistModel[] = [
  {
    step: ChecklistStep.CREATE,
    title: marker('CHECKLIST.CREATE.TITLE'),
    description: marker('CHECKLIST.CREATE.DESCRIPTION'),
    completed: true,
    minor: true,
  },
  {
    step: ChecklistStep.LIBRARY_ADD_TRACK,
    title: marker('CHECKLIST.LIBRARY.TITLE'),
    description: marker('CHECKLIST.LIBRARY.DESCRIPTION'),
    url: '/media/bac/1',
    completed: false,
    firstButton: marker('CHECKLIST.LIBRARY.ADD_TRACK'),
    firstAction: new CheckListAddTrack(),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_LIBRARY_DESKTOP,
    secondButton: marker('CHECKLIST.LIBRARY.GET_FREE_TRACKS'),
    secondAction: new AddDefaultTracksRequest(),
    secondButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_LIBRARY_FREE,
    secondButtonDisabledTooltip: marker('CHECKLIST.LIBRARY.ALREADY_ADDED'),
  },
  {
    step: ChecklistStep.CREATE_PLAYLIST,
    title: marker('CHECKLIST.PLAYLISTS.TITLE'),
    description: marker('CHECKLIST.PLAYLISTS.DESCRIPTION'),
    url: '/media',
    completed: false,
    firstButton: marker('CHECKLIST.PLAYLISTS.ADD_PLAYLIST'),
    firstAction: new CheckListAddPlaylist(),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_PLAYLIST_NEW,
  },
  {
    step: ChecklistStep.ADD_TO_PLANNING,
    title: marker('CHECKLIST.PLANNING.TITLE'),
    description: marker('CHECKLIST.PLANNING.DESCRIPTION'),
    url: '/scheduler/planning',
    completed: false,
    firstButton: marker('CHECKLIST.PLANNING.ADD_PLANNING'),
    firstAction: new CheckListPlanning(),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_PLANNING_NEW,
  },
  {
    step: ChecklistStep.REGENERATE,
    title: marker('CHECKLIST.GENERATION.TITLE'),
    description: marker('CHECKLIST.GENERATION.DESCRIPTION'),
    url: '/generated-planning',
    completed: false,
    firstButton: marker('CHECKLIST.GENERATION.GENERATE'),
    firstAction: new CheckListGenerate(),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_GENERATION_NEW,
  },
  {
    step: ChecklistStep.LISTEN_TO_RADIO,
    title: marker('CHECKLIST.RADIO.TITLE'),
    description: marker('CHECKLIST.RADIO.DESCRIPTION'),
    url: '/',
    completed: false,
    firstButton: marker('CHECKLIST.RADIO.START_RADIO'),
    firstAction: new CheckListListenToRadio(),
    firstButtonDisabledTooltip: marker('GLOBAL.DISABLED.NO_TRACKS_TOOLTIP_CONTENT'),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_RADIO_CTA,
  },
  {
    step: ChecklistStep.SHARE_SMARTLINK,
    title: marker('CHECKLIST.SHARE.TITLE'),
    description: marker('CHECKLIST.SHARE.DESCRIPTION'),
    url: '/widgets/smartlink',
    completed: false,
    firstButton: marker('CHECKLIST.SHARE.SHARE'),
    firstAction: new CheckListShare(),
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_SHARE_NEW,
  },
  {
    step: ChecklistStep.SHOW_TUTORIAL,
    title: marker('CHECKLIST.TUTORIAL.TITLE'),
    description: marker('CHECKLIST.TUTORIAL.DESCRIPTION'),
    url: '/',
    completed: false,
    firstButton: marker('CHECKLIST.TUTORIAL.DISCOVER_HELP_CENTER'),
    firstAction: new CheckListHelpCenter(),
    minor: true,
    firstButtonEvent: AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_TUTORIAL_NEW,
  },
];

@State<OnboardingChecklistStateModel>({
  name: 'onboardingChecklist',
  defaults: {
    steps,
    opened: false,
    completed: false,
    stepOpened: ChecklistStep.LIBRARY_ADD_TRACK,
    redirectUploadStep: false,
    destroyed: false,
  },
})
@Injectable()
export class OnboardingChecklistState {
  constructor(
    private readonly store: Store,
    private readonly checklistService: OnboardingChecklistService,
  ) {}
  @Selector()
  static steps(state: OnboardingChecklistStateModel): OnboardingChecklistModel[] {
    return state.steps;
  }

  @Selector()
  static opened(state: OnboardingChecklistStateModel): boolean {
    return state.opened;
  }

  @Selector()
  static stepOpened(state: OnboardingChecklistStateModel): ChecklistStep {
    return state.stepOpened;
  }

  @Selector()
  static firstNotCompletedIndex(state: OnboardingChecklistStateModel): number {
    return state.steps.findIndex(step => !step.completed);
  }

  @Selector()
  static notCompletedSteps(state: OnboardingChecklistStateModel): number {
    return state.steps.filter(step => !step.completed).length;
  }

  @Selector()
  static completed(state: OnboardingChecklistStateModel): boolean {
    return state.completed;
  }

  @Selector()
  static destroyed(state: OnboardingChecklistStateModel): boolean {
    return state.destroyed;
  }

  @Selector()
  static progression(state: OnboardingChecklistStateModel): number {
    return (
      state.steps.filter(s => !s.minor && s.completed).length * 15 +
      state.steps.filter(s => s.minor && s.completed).length * 5
    );
  }

  @Selector()
  static stepCompleted(
    state: OnboardingChecklistStateModel,
  ): (step: ChecklistStep) => boolean {
    return step => state.steps.find(s => s.step === step).completed;
  }

  @Action(ChecklistRequest)
  getChecklist(ctx: StateContext<OnboardingChecklistStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.checklistService.getChecklist(id)),
      mergeMap(completed => ctx.dispatch(new ChecklistSuccess(completed))),
      catchError(err => ctx.dispatch(new ChecklistFailure(err))),
    );
  }

  @Action(ChecklistSuccess)
  getChecklistSuccess(
    ctx: StateContext<OnboardingChecklistStateModel>,
    { completed }: ChecklistSuccess,
  ) {
    const steps = [...ctx.getState().steps];
    steps
      .filter(s => s.step !== ChecklistStep.CREATE)
      .forEach(s => (s.completed = false));
    completed.forEach(step => {
      steps.find(s => s.step === step).completed = true;
    });
    ctx.patchState({
      steps,
    });
  }

  @Action(CompleteStep)
  completeStep(ctx: StateContext<OnboardingChecklistStateModel>, { step }: CompleteStep) {
    const mStep = { ...ctx.getState().steps.find(s => s.step === step) };
    const isDemo = this.store.selectSnapshot(LiveTrackingState.planId) < 2;
    if (mStep.completed || ctx.getState().destroyed || !isDemo) {
      throw new Error('Already completed');
    }
    mStep.completed = true;
    ctx.setState(
      patch({
        steps: updateItem(st => st.step === mStep.step, mStep),
      }),
    );
    const remaining = !!ctx.getState().steps.find(s => s.completed === false);
    if (!remaining) {
      ctx.patchState({ completed: true });
      aLogEvent(AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_CONGRATULATIONS);
    }
    const completed = ctx
      .getState()
      .steps.filter(s => s.completed)
      .map(s => s.step);
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.checklistService.completeStep(id, completed)),
      mergeMap(() => ctx.dispatch(new PutChecklistSuccess())),
      catchError(err => ctx.dispatch(new PutChecklistFailure(err))),
    );
  }

  @Action(ToggleChecklist)
  toggleChecklist(
    ctx: StateContext<OnboardingChecklistStateModel>,
    { opened }: ToggleChecklist,
  ) {
    ctx.patchState({
      opened,
    });
  }

  @Action(ToggleChecklistStep)
  toggleChecklistStep(
    ctx: StateContext<OnboardingChecklistStateModel>,
    { stepOpened }: ToggleChecklistStep,
  ) {
    aLogEvent(AMPLITUDE_EVENT.CHECKLIST[stepOpened]);
    ctx.patchState({
      stepOpened,
    });
  }

  @Action([TracksUploaded, AddDefaultTracksSuccess])
  completeStepTracks(ctx: StateContext<OnboardingChecklistStateModel>) {
    ctx.dispatch(new CompleteStep(ChecklistStep.LIBRARY_ADD_TRACK));
  }

  @Action(AddDefaultTracksSuccess)
  disableDefaultTrackButton(ctx: StateContext<OnboardingChecklistStateModel>) {
    const mStep = {
      ...ctx.getState().steps.find(s => s.step === ChecklistStep.LIBRARY_ADD_TRACK),
    };
    mStep.secondButtonDisabled = true;
    ctx.setState(
      patch({
        steps: updateItem(st => st.step === mStep.step, mStep),
      }),
    );
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
    ctx.dispatch(new SchedulerAllTracksRequest(radioId));
  }

  @Action(PlayRadio)
  completeStepRadio(ctx: StateContext<OnboardingChecklistStateModel>) {
    ctx.dispatch(new CompleteStep(ChecklistStep.LISTEN_TO_RADIO));
  }

  @Action([AddToPlaylistSuccess])
  completeStepPlaylist(ctx: StateContext<OnboardingChecklistStateModel>) {
    ctx.dispatch(new CompleteStep(ChecklistStep.CREATE_PLAYLIST));
  }

  @Action(PlanningCreateSlotSuccess)
  completeStepPlanning(ctx: StateContext<OnboardingChecklistStateModel>) {
    ctx.dispatch(new CompleteStep(ChecklistStep.ADD_TO_PLANNING));
  }

  @Action([GenerateDayRequest, RegenerateCurrentDayRequest])
  completeStepGeneration(
    ctx: StateContext<OnboardingChecklistStateModel>,
    { auto }: GenerateDayRequest,
  ) {
    if (!auto) {
      ctx.dispatch(new CompleteStep(ChecklistStep.REGENERATE));
    }
  }

  @Action(CheckListHelpCenter)
  completeStepHelpCenter(ctx: StateContext<OnboardingChecklistStateModel>) {
    window.open('https://help.radioking.com', '_blank');
    ctx.dispatch(new CompleteStep(ChecklistStep.SHOW_TUTORIAL));
  }

  @Action(ToggleFirstButton)
  toggleFirstButton(
    ctx: StateContext<OnboardingChecklistStateModel>,
    { step, disabled }: ToggleFirstButton,
  ) {
    const mStep = { ...ctx.getState().steps.find(s => s.step === step) };
    mStep.firstButtonDisabled = disabled;
    ctx.setState(
      patch({
        steps: updateItem(st => st.step === mStep.step, mStep),
      }),
    );
  }

  @Action(DestroyChecklist)
  destroyChecklist(ctx: StateContext<OnboardingChecklistStateModel>) {
    ctx.patchState({ destroyed: true });
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
